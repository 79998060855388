@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-body text-paragraph leading-6;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-white font-primary;
  }
  .section {
    @apply py-12 lg:py-32;
  }
  .section-title {
    @apply text-3xl lg:text-4xl font-medium lg:font-extrabold mb-5;
  }
  .subtitle {
    @apply text-base text-paragraph mb-16 lg:mb-24 max-w-[520px];
  }
  .btn {
    @apply py-4 px-7 font-medium text-white flex items-center justify-center rounded-sm transition-all;
  }
  .btn-lg {
    @apply h-[54px];
  }
  .btn-md {
    @apply h-[48px];
  }
  .input {
    @apply bg-secondary text-paragraph h-[60px] outline-none pl-6 w-full font-body text-[15px] rounded-sm focus:outline focus:outline-1 focus:outline-accent;
  }
  .textarea {
    @apply bg-secondary resize-none w-full outline-none p-6 rounded-sm h-[200px] focus:outline focus:outline-1 focus:outline-accent;
  }
  .active {
    @apply text-accent;
  }
}
@layer components {
    @keyframes shine {
      to {
        background-position: 200% center;
      }
    }

    .snip1584 * {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
    }
    .snip1584:before {
      transition: all 0.25s ease;
      -webkit-transition-delay: 0.25s;
      transition-delay: 0.25s;
    }

    .snip1584 h3 {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
      text-transform: uppercase;
      font-weight: 400;
      -webkit-transition-delay: 0.05s;
      transition-delay: 0.05s;
      margin-bottom: 5px;
    }
    .snip1584 h5 {
      font-size: 12px;
      background-color: #ae895d;
      padding: 3px 10px;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
    .snip1584:hover:before,
    .snip1584.hover:before {
      top: 10px;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
    .snip1584:hover h3,
    .snip1584.hover h3,
    .snip1584:hover h5,
    .snip1584.hover h5 {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    .snip1584:hover h3,
    .snip1584.hover h3 {
      -webkit-transition-delay: 0.3s;
      transition-delay: 0.3s;
    }
    .snip1584:hover h5,
    .snip1584.hover h5 {
      -webkit-transition-delay: 0.2s;
      transition-delay: 0.2s;
    }
    
  }

